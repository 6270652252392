import "./Title.css";

import React, { Component } from "react";

class Title extends Component {
  render() {
    return (
      <div className="TitleBar">
        <span className="Title">Joe Schaefer</span>
      </div>
    );
  }
}

export default Title;
